import React, { FunctionComponent, useEffect, useState } from "react";
import MainButton, { ButtonStyle } from "./Common/MainButton";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import {
  Globe,
  Instagram,
  Target,
  TrendingUp,
  Twitter,
  Youtube,
} from "react-feather";
import { FunnelRoutes } from "../index";

interface INavbar {}

const DESKTOP_WIDTH = 850;

const Navbar: FunctionComponent<INavbar> = (props) => {
  const { pathname } = useLocation();

  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
  const isDesktop = screenWidth >= DESKTOP_WIDTH;

  useEffect(() => {
    window.addEventListener("scroll", handleScrolling, true);
    window.addEventListener("resize", handleResize, true);
    return () => {
      window.removeEventListener("scroll", handleScrolling, true);
      window.removeEventListener("resize", handleResize, true);
    };
  }, []);

  function handleScrolling() {
    setIsScrolling(window.scrollY > 0);
  }

  function handleResize() {
    setScreenWidth(window.innerWidth);
  }

  function renderIcon(link: FunnelRoutes, isOnPath: boolean) {
    const classes = isOnPath || isScrolling ? "text-white" : "text-neutral-400";

    if (link === FunnelRoutes.Youtube) return <Youtube className={classes} />;
    if (link === FunnelRoutes.Consulting)
      return <TrendingUp className={classes} />;
    if (link === FunnelRoutes.Mastermind) return <Target className={classes} />;
    return null;
  }

  function renderButton(link: FunnelRoutes, text: string) {
    const isOnPath = pathname === link;
    const activeBtn = isOnPath || isScrolling;

    const btn = isDesktop ? (
      <MainButton
        buttonStyle={activeBtn ? ButtonStyle.Filled : ButtonStyle.Blank}
        small={true}
        noShadow={true}
        text={text}
        noMargin={true}
        classes={"min-w-[205px]"}
        textClass={"-mb-[5px]"}
      />
    ) : (
      <button
        className={`${
          activeBtn ? "bg-[#C03540]" : "bg-neutral-900"
        } flex h-11 w-14 items-center justify-center rounded-md px-2 transition-all duration-300 ease-in-out`}
      >
        {renderIcon(link, isOnPath)}
      </button>
    );

    return <Link to={link}>{btn}</Link>;
  }

  function renderButtons() {
    return (
      <div className="mr-6 ml-12 flex gap-4 md:ml-0">
        {renderButton(FunnelRoutes.Youtube, "Youtube Growth")}
        {renderButton(FunnelRoutes.Consulting, "Web3 Project Marketing")}
        {renderButton(FunnelRoutes.Mastermind, "Coaching & Mastermind")}
      </div>
    );
  }

  function renderSocials() {
    if (!isDesktop) return null;

    return (
      <div className="mr-6 hidden flex-row justify-center gap-5 text-neutral-400 lg:flex">
        <button
          className="flex items-center justify-center rounded-md bg-neutral-900 px-2 transition-all duration-300 ease-in-out hover:border-neutral-800 hover:bg-neutral-800"
          onClick={() =>
            window.open("https://www.instagram.com/hustle_nft", "_blank")
          }
        >
          <Instagram />
        </button>
        <button
          className="flex items-center justify-center rounded-md bg-neutral-900 px-2 transition-all duration-300 ease-in-out hover:border-neutral-800 hover:bg-neutral-800"
          onClick={() =>
            window.open("https://twitter.com/HustlepediaYT", "_blank")
          }
        >
          <Twitter />
        </button>
        <button
          className="flex items-center justify-center rounded-md bg-neutral-900 px-2 transition-all duration-300 ease-in-out hover:border-neutral-800 hover:bg-neutral-800"
          onClick={() => window.open("https://ttoonft.io/", "_blank")}
        >
          <Globe />
        </button>
      </div>
    );
  }

  function renderNav() {
    return (
      <nav
        className={
          "transition-background fixed -top-[1px] right-0 left-0 z-50 h-[82.5px] w-full border-b-[1px] border-[#262626] bg-[#0F0F0F] p-4 shadow-md md:visible"
        }
      >
        <div
          className={`mt-1 flex content-center items-center justify-between transition-all duration-300 ease-in-out ${
            isScrolling && isDesktop
              ? "ml-24 mr-12 shadow-xl"
              : isDesktop
              ? "ml-8 mr-0"
              : "r-0 ml-5"
          }`}
        >
          <div className="-mt-[10px] ml-4 mr-10 flex items-center">
            <Link to={"/"}>
              <img
                src={"/images/logo.png"}
                alt={"logo"}
                className="h-[55px] min-w-[40px]"
              />
            </Link>
          </div>
          <div className="-mt-0.5 flex gap-2">
            {renderButtons()}
            {renderSocials()}
          </div>
        </div>
      </nav>
    );
  }

  return <header>{renderNav()}</header>;
};

export default Navbar;
