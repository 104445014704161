import React, { useRef } from "react";
import Navbar from "../components/Navbar";
import Landing from "../components/landing/Landing";
import Footer from "../components/Footer";
import Content from "../components/landing/Content";
import { AnimatePresence } from "framer-motion";
import Testimonials from "../components/landing/Testimonials";
import About from "../components/landing/About";

function Home() {
  const learnMoreRef = useRef<HTMLDivElement>(null);
  const aboutRef = useRef<HTMLDivElement>(null);

  function scrollToRef(ref: React.RefObject<HTMLDivElement>) {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }

  return (
    <AnimatePresence mode="wait" initial={false}>
      <Navbar />
      <Landing
        scrollToLearnMore={() => scrollToRef(learnMoreRef)}
        scrollToAbout={() => scrollToRef(aboutRef)}
      />
      <Content learnMoreRef={learnMoreRef} />
      <Testimonials />
      <About aboutRef={aboutRef} />
      <Footer />
    </AnimatePresence>
  );
}

export default Home;
