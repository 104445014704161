import React, { FunctionComponent } from "react";
import Tiers from "./Tiers";

interface IContent {
  learnMoreRef: React.RefObject<HTMLDivElement>;
}

const Content: FunctionComponent<IContent> = (props) => {
  const { learnMoreRef } = props;

  return (
    <div className="bg-[#C03540] md:py-4">
      <div
        className="m-auto flex flex-col items-center md:pb-20"
        ref={learnMoreRef}
      >
        <h2 className="m-auto mb-8 mt-20 text-center text-4xl tracking-wide md:mt-40 md:text-6xl">
          Master every aspect
        </h2>
        <Tiers />
      </div>
    </div>
  );
};

export default Content;
