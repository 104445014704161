import React, { FunctionComponent } from "react";
import { motion } from "framer-motion";
import Tier, { TIERS } from "../Common/Tier";

interface ITiers {}

const Tiers: FunctionComponent<ITiers> = (props) => {
  function renderTiers() {
    return TIERS.map((tier) => <Tier key={`tier-${tier.tag}`} tier={tier} />);
  }

  return (
    <div className="z-10 w-full">
      <div className="flex flex-col text-center">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          className="mt-12 mb-12 flex flex-col flex-wrap items-center justify-center gap-6 md:mb-32 md:mt-24 md:flex-row"
        >
          {renderTiers()}
        </motion.div>
      </div>
    </div>
  );
};

export default Tiers;
