import React, { FunctionComponent } from "react";
import { motion } from "framer-motion";

interface IAbout {
  aboutRef: React.RefObject<HTMLDivElement>;
}

const About: FunctionComponent<IAbout> = (props) => {
  const { aboutRef } = props;

  return (
    <div
      className="m-auto flex flex-col items-center justify-center pt-12 md:pt-32"
      ref={aboutRef}
    >
      <div className="relative m-auto mb-12 flex flex-col-reverse md:mb-40 md:flex-row">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          className="relative mb-8 md:mr-24 md:mb-16"
        >
          <img
            src="/images/hustle.png"
            className="m-auto mt-12 h-[26rem] rounded-lg align-middle shadow-lg drop-shadow-lg md:mt-0 md:h-[32rem]"
            alt="Hustlepedia"
          />
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          className="max-w-[350px] md:max-w-[550px]"
        >
          <h1 className="text-4xl tracking-wide md:text-5xl">
            About <span className="text-[#C03540]">Hustle</span>
          </h1>
          <p className="mt-6 text-sm md:mt-12 md:text-[16px]">
            Host on Crypto Banter, 3rd Largest North American Crypto Youtube
            Channel. Founder of Hustlepedia YT Channel with over 1.5 Million
            Views
          </p>
          <p className="mt-4 text-sm md:text-[16px]">
            Launched This Thing of Ours NFT Project in August, 2022, Sold Out in
            7 minutes in a bear market
          </p>
          <p className="mt-4 text-sm md:text-[16px]">
            Helped build some of the biggest faceless AND personal branded
            Youtube channels for multiple companies and personalities in
            numerous niches from 2016-2021
          </p>
          <p className="mt-4 text-sm md:text-[16px]">
            Over 10 Million Views and Counting on Hustle's show on Crypto Banter
          </p>
          <p className="mt-4 text-sm md:text-[16px]">
            Investor in Crypto Beginning in 2017 with Numerous 10-30x Calls in
            the Process to Private Masterminds and Networks Publicly
          </p>
          <p className="mt-4 text-sm md:text-[16px]">
            Advisory to numerous top Crypto Gaming and NFT Projects such as
            WAGMI Games, Steady Stack, Collider Craftworks and more
          </p>
          <p className="mt-4 text-sm md:text-[16px]">
            Generated multi-millions in project revenue via marketing for
            previous advisories for both Games and NFT projects
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default About;
