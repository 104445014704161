import React, { FunctionComponent } from "react";
import { Check } from "react-feather";
import MainButton, { ButtonStyle } from "./MainButton";
import { Link } from "react-router-dom";

export interface ITier {
  name: string;
  tag: string;
  includes: string[];
  link: string;
}

export const TIERS: ITier[] = [
  {
    name: "Youtube Growth",
    tag: "Course",
    includes: [
      "Maximize The YouTube Algorithm",
      "Video and Content Strategy",
      "Picking a Niche",
      "Thumbnail Design",
      "YouTube Titles",
      "YouTube Description Optimization",
      "YouTube Tags Optimization",
      "The Snowball Effect from ONE Viral Video",
    ],
    link: "/youtube_growth",
  },
  {
    name: "Web3 Project Marketing",
    tag: "Agency",
    includes: [
      "Marketing Strategies to maximize exposure to the project. Including Twitter, Instagram, Youtube, Discord and More",
      "Marketing to a network of 700K+ between social media channels on Twitter, Youtube, Instagram, TikTok and more",
      "Strategy and expertise on marketing and project promotion and social media growth from Hustle himself",
      "Access a vast network of influencer connections and other project connections for potential collaboration efforts",
      "Content Strategy, Marketing and much more",
    ],
    link: "/consulting",
  },
  {
    name: "Coaching & Mastermind",
    tag: "Program",
    includes: [
      "Cash Flow and Business Ideas",
      "Crypto, NFTs & DeFi",
      "Real Estate & Air BNB",
      "E-Commerce",
      "Stocks & Options Trading",
      "Personal Brand Growth",
      "Youtube Growth",
      "Exotic Car Rental Business",
      "Wealth Management Strategies",
    ],
    link: "/mastermind",
  },
];

interface ITierProps {
  tier: ITier;
}

const Tier: FunctionComponent<ITierProps> = (props) => {
  const { tier } = props;
  const { includes, tag, name, link } = tier;

  function renderIncludes() {
    return includes.map((feature) => {
      return (
        <li key={feature} className="mx-6 mb-2">
          <div className="flex min-h-[30px] flex-row items-start">
            <span className="mr-4 h-[24px] w-[24px] rounded-sm rounded-2xl bg-neutral-800 p-[3px]">
              <Check size={18} className="text-[#C03540]" />
            </span>
            <p className="max-w-sm text-left text-[11.5px] text-neutral-500 sm:text-[13.5px]">
              {feature}
            </p>
          </div>
        </li>
      );
    });
  }

  return (
    <div
      className={
        "mb-4 flex min-h-[615px] w-[335px] select-none flex-col justify-between overflow-hidden rounded-lg bg-neutral-900 pt-10 text-center shadow-lg drop-shadow-lg sm:w-[480px] md:mb-0 md:h-full"
      }
    >
      <div>
        <h4 className="m-auto text-center text-3xl tracking-wide text-white">
          {name}
        </h4>
        <div className="m-auto mt-6 max-w-[160px] rounded-md border border-[#C03540] p-[3px] font-bold">
          <p className="mt-[1px] text-[13px] text-[#C03540]">{tag}</p>
        </div>
      </div>
      <ul className="m-auto list-none pt-7 pb-4">{renderIncludes()}</ul>
      <div className="border-t border-neutral-800 p-6 pt-2">
        <div className="mt-5">
          <Link to={link}>
            <MainButton
              small={true}
              text={"Learn More"}
              noMargin={true}
              buttonStyle={ButtonStyle.Filled}
              classes={"min-w-[210px] min-h-[47px]"}
              textClass={"leading-8 -mt-1"}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Tier;
