import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import ClickFunnel from "./pages/ClickFunnel";

enum FunnelSources {
  Youtube = "https://hustle.clickfunnels.com/sales-page1666484181490",
  Mastermind = "https://hustle.clickfunnels.com/squeeze-page1670190175870",
  Consulting = "https://hustle.clickfunnels.com/squeeze-page1672518976417",
}

export enum FunnelRoutes {
  Youtube = "/youtube_growth",
  Mastermind = "/mastermind",
  Consulting = "/consulting",
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: FunnelRoutes.Youtube,
    element: <ClickFunnel src={FunnelSources.Youtube} />,
  },
  {
    path: FunnelRoutes.Mastermind,
    element: <ClickFunnel src={FunnelSources.Mastermind} />,
  },
  {
    path: FunnelRoutes.Consulting,
    element: <ClickFunnel src={FunnelSources.Consulting} />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<RouterProvider router={router} />);
