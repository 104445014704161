import React, { FunctionComponent } from 'react';

export enum ButtonStyle {
  Filled = 'filled',
  Outlined = 'outlined',
  Blank = 'blank',
}

interface IMainButton {
  text: string;
  type?: 'button' | 'submit' | 'reset';
  large?: boolean;
  small?: boolean;
  buttonStyle?: ButtonStyle;
  noMargin?: boolean;
  noShadow?: boolean;
  onClick?: (value?: any) => any;
  responsive?: boolean;
  classes?: string;
  disabled?: boolean;
  linkTo?: string;
  bgColor?: string;
  textClass?: string;
  loading?: boolean;
}

const MainButton: FunctionComponent<IMainButton> = (props) => {
  const {
    text,
    type,
    buttonStyle,
    large,
    small,
    noMargin,
    noShadow,
    responsive,
    onClick,
    classes,
    disabled,
    linkTo,
    bgColor,
    textClass,
    loading,
  } = props;
  const isFilled = buttonStyle === ButtonStyle.Filled;
  const isDisabled = disabled || loading;
  const baseStyle = isFilled ? 'bg-[#C03540] px-5 py-3' : 'text-primary';
  const disabledStyle = `disabled:shadow-none ${
    loading
      ? 'disabled:bg-red-100 disabled:border-red-100'
      : 'disabled:bg-red-200 disabled:border-neutral-200'
  }`;
  const combinedClasses = `${baseStyle} ${
    buttonStyle === ButtonStyle.Blank
      ? 'border-neutral-800 bg-[#171717] hover:border-neutral-800 hover:bg-neutral-800'
      : `${bgColor ? `border-[${bgColor}]` : 'border-none'} ${
          buttonStyle === ButtonStyle.Filled
            ? 'hover:bg-red-600 hover:border-red-600 hover:shadow-md'
            : ''
        }`
  } ${classes}`;

  const button = (
    <button
      onClick={onClick}
      type={type ?? 'button'}
      disabled={isDisabled}
      className={`${combinedClasses} rounded-[200px] transition-all duration-300 ease-in-out ${
        noMargin ? '' : 'mr-2 mb-2'
      } ${small ? 'max-w-sm-[155px] h-10 min-w-[205px]' : 'h-14'}  ${
        large ? 'md:w-64' : small ? 'w-[115px]' : 'md:w-48'
      } rounded-md border px-5 py-3 text-center text-sm ${
        noShadow || !isFilled ? '' : 'shadow-sm'
      } ${responsive ? 'w-[300px]' : ''} ${disabledStyle}`}>
      <h6
        className={`duration-200 ${
          small ? '-mt-[3px] text-sm' : 'mt-1 text-lg'
        } tracking-wide  ${
          !isFilled ? 'text-neutral-400' : 'text-white'
        } ${textClass} ${isDisabled ? 'text-neutral-800 opacity-50' : ''}`}>
        {text}
      </h6>
    </button>
  );

  return linkTo ? <a href={linkTo}>{button}</a> : button;
};

export default MainButton;
