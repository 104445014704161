import React from "react";
import Navbar from "../components/Navbar";

interface IClickFunnel {
  src: string;
}

function ClickFunnel(props: IClickFunnel) {
  const { src } = props;
  return (
    <div>
      <Navbar />
      <iframe
        title={"funnel"}
        src={src}
        className="h-screen w-screen pt-[83px]"
      />
    </div>
  );
}

export default ClickFunnel;
