import React, { FunctionComponent } from "react";
import MainButton, { ButtonStyle } from "../Common/MainButton";

interface ILanding {
  scrollToLearnMore: () => void;
  scrollToAbout: () => void;
}

const Landing: FunctionComponent<ILanding> = (props) => {
  const { scrollToLearnMore, scrollToAbout } = props;

  return (
    <div className="m-auto flex h-auto min-h-screen max-w-screen-lg flex-col items-center justify-center text-center md:h-screen md:flex-row md:text-left">
      <div className="md:mt-18 mx-4 mt-20 mb-1 flex flex-col items-center text-center md:mx-20 md:mb-8">
        <h1 className="text-5xl tracking-wide md:text-7xl">
          <span className="text-[#C03540]">
            Up your Game
            <br />{" "}
          </span>
          <span className="text-3xl tracking-wide md:text-5xl">
            with Hustlepedia
          </span>
        </h1>
        <p className="mt-3 max-w-[600px] text-center text-sm leading-7 md:text-[16px]">
          Crypto Gaming Maxi, Host at Crypto Banter,
          <br /> Founder TTOONFT, Web3 Gaming & NFT Advisor
        </p>
        <div className="mt-12 flex flex-col gap-4 md:mt-16 md:flex-row">
          <MainButton
            text={"About Hustle"}
            buttonStyle={ButtonStyle.Blank}
            responsive={true}
            classes={"md:min-w-[255px]"}
            textClass={"-mt-[1px]"}
            onClick={scrollToAbout}
          />
          <MainButton
            text={"Get Started"}
            buttonStyle={ButtonStyle.Filled}
            responsive={true}
            classes={"md:min-w-[255px]"}
            onClick={scrollToLearnMore}
          />
        </div>
      </div>
    </div>
  );
};

export default Landing;
