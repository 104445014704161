import React, { FunctionComponent } from "react";

interface ITestimonials {}

const Testimonials: FunctionComponent<ITestimonials> = (props) => {
  return (
    <div className="m-auto mt-0 flex flex-col items-center justify-center pb-16 md:mt-24">
      {/*<div className='m-auto mx-6 grid grid-cols-1 items-center gap-8 md:mx-0 md:grid-cols-3 md:gap-14'>
        <Testimonial
          name={'Davy Jones'}
          text={
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ornare mattis mi ut condimentum. Suspendisse at sagittis augue. Phasellus consectetur molestie scelerisque'
          }
          role={'WEB3 Gamer'}
          instagram={'https://www.instagram.com'}
          tiktok={'https://www.instagram.com'}
          avatar={'/media/stock_avatar.jpg'}
        />
        <Testimonial
          name={'Davy Jones'}
          text={
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ornare mattis mi ut condimentum. Suspendisse at sagittis augue. Phasellus consectetur molestie scelerisque'
          }
          role={'WEB3 Gamer'}
          instagram={'https://www.instagram.com'}
          tiktok={'https://www.instagram.com'}
          avatar={'/media/stock_avatar.jpg'}
        />
        <Testimonial
          name={'Davy Jones'}
          text={
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ornare mattis mi ut condimentum. Suspendisse at sagittis augue. Phasellus consectetur molestie scelerisque'
          }
          role={'WEB3 Gamer'}
          instagram={'https://www.instagram.com'}
          tiktok={'https://www.instagram.com'}
          avatar={'/media/stock_avatar.jpg'}
        />
        <Testimonial
          name={'Davy Jones'}
          text={
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ornare mattis mi ut condimentum. Suspendisse at sagittis augue. Phasellus consectetur molestie scelerisque'
          }
          role={'WEB3 Gamer'}
          instagram={'https://www.instagram.com'}
          tiktok={'https://www.instagram.com'}
          avatar={'/media/stock_avatar.jpg'}
        />
        <Testimonial
          name={'Davy Jones'}
          text={
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ornare mattis mi ut condimentum. Suspendisse at sagittis augue. Phasellus consectetur molestie scelerisque'
          }
          role={'WEB3 Gamer'}
          instagram={'https://www.instagram.com'}
          tiktok={'https://www.instagram.com'}
          avatar={'/media/stock_avatar.jpg'}
        />
        <Testimonial
          name={'Davy Jones'}
          text={
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ornare mattis mi ut condimentum. Suspendisse at sagittis augue. Phasellus consectetur molestie scelerisque'
          }
          role={'WEB3 Gamer'}
          instagram={'https://www.instagram.com'}
          tiktok={'https://www.instagram.com'}
          avatar={'/media/stock_avatar.jpg'}
        />
      </div>*/}
    </div>
  );
};

export default Testimonials;
