import React, { FunctionComponent } from "react";
import { Globe, Instagram, Twitter } from "react-feather";

interface IFooter {}

const Footer: FunctionComponent<IFooter> = (props) => {
  return (
    <footer className="flex h-[200px] flex-col justify-center bg-[#0c0c0c] text-center align-middle">
      <div className="mt-6 flex flex-col">
        <div className="mb-6 flex flex-row justify-center gap-5 text-neutral-400">
          <button
            onClick={() =>
              window.open("https://www.instagram.com/hustle_nft", "_blank")
            }
          >
            <Instagram />
          </button>
          <button
            onClick={() =>
              window.open("https://twitter.com/HustlepediaYT", "_blank")
            }
          >
            <Twitter />
          </button>
          <button onClick={() => window.open("https://ttoonft.io/", "_blank")}>
            <Globe />
          </button>
        </div>
        <span className="text-sm text-neutral-700">
          © {new Date().getFullYear()} Hustlepedia™ All Rights Reserved.
        </span>
        <p className="mt-4 text-sm text-neutral-700">
          Site by{" "}
          <a
            className="text-[#C03540]"
            href={"https://github.com/sanderhelleso"}
            target={"_blank"}
            rel="noreferrer"
          >
            0xNxt.eth
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
